var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dragValue
    ? _c(
        "div",
        { staticClass: "ExcelImport" },
        [
          _c("HeaderBack", {
            attrs: { title: _vm.title },
            on: { back: _vm.toggleValue },
          }),
          _c(
            "div",
            { staticClass: "ExcelImport-content" },
            [
              _c(
                "div",
                { staticClass: "importType" },
                [
                  _c("p", [_vm._v("匹配方式：")]),
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      model: {
                        value: _vm.matchField,
                        callback: function ($$v) {
                          _vm.matchField = $$v
                        },
                        expression: "matchField",
                      },
                    },
                    _vm._l(_vm.import_matchField_type, function (item) {
                      return _c(
                        "a-radio",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("BatchImport", {
                ref: "batchimport",
                attrs: {
                  "show-update-list": true,
                  multiple: false,
                  "file-list": _vm.fileList,
                  "file-size": 20,
                  "data-list": _vm.dataList,
                  "match-field": _vm.matchField,
                  "import-template": _vm.importTemplate,
                },
              }),
            ],
            1
          ),
          _c("a-row", {
            staticClass: "ExcelImport-bottom",
            attrs: { type: "flex", justify: "center" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }